// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "core-js/stable"
import "regenerator-runtime/runtime"
import "trix"
import "@rails/actiontext"

require("jquery")
require("select2")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("jquery.easing")
require("src/theme")
require("src/search")
require("src/form-needs-validation")
require("src/custom-file-input")
require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Local files (load last)
import "../stylesheets/application"

$(document).ready(function() {
  // Search alert
  // $('#new_search_alert_form').submit(function(e) {
  //   e.preventDefault;

  //   var $form = $(this);
  //   var url = $form.attr('action');

  //   $.ajax({
  //     type: "POST",
  //     url: url,
  //     data: $form.serialize(),
  //     success: function(data) {
  //     }
  //   })
  // })

  $('.show-info').on('click', '.show-favourite-btn', function(e) {
    var $el = $(this);
    var petId = $el.data('pet');
    var directoryId = $el.data('directory');
    var favouritableId = undefined;
    var url = ''
    if (petId != undefined) {
      favouritableId = petId;
      url = '/pet_favourites';
    } else if (directoryId != undefined) {
      favouritableId = directoryId;
      url = '/directory_favourites';
    }

    var fillHeart = function() {
      $el.removeClass('far');
      $el.addClass('fas');
    }
    var emptyHeart = function() {
      $el.removeClass('fas');
      $el.addClass('far');
    }

    function sendRequest(url, method, favouritableId) {
      var args = { url: url + ".json", type: method }
      if (method == 'POST') {
        args.data = { id: favouritableId }
      }

      $.ajax(args);
    }

    if ($el.hasClass('fas') || $el.data('prefix') == 'fas') {
      emptyHeart();

      // Delete favourite
      sendRequest(url + '/' + favouritableId, 'DELETE', favouritableId);
    } else {
      fillHeart();

      // Create favourite
      sendRequest(url, 'POST', favouritableId);
    }
  });

  // Login Modal
  var fragment = window.location.hash;

  if (['#signin', '#signup', '#user_password'].includes(fragment)) {
    $('#userLoginModal').modal('show');
    var carouselMap = {
      '#signin': 0,
      '#signup': 1,
      '#user_password': 2
    }
    $('#userLoginPages').carousel(carouselMap[fragment]);
  }
  if (['#search_alert'].includes(fragment)) {
    $('#createSearchAlertModal').modal('show');
  }
  if (['#pet_enquiry'].includes(fragment)) {
    $('#createPetEnquiryModal').modal('show');
  }
});

require("trix")
require("@rails/actiontext")
