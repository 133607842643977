$(document).ready(function() {
  $('.search-select').select2();

  $('#per_page').change(function() {
    $form = $(this).parents().filter("form");
    $('#page')[0].value = 1;
    $form[0].submit();
  });

  paginationLinkClicked = function(e, pageValue) {
    $form = $(e).parents().filter("form");
    $e = $(e);

    active = $e.parent().hasClass('active');
    disabled = $e.parent().hasClass('disabled');

    if (!(active && disabled)) {
      $('.page-item.active').addClass('disabled');
      $('.page-item.active').removeClass('active');
      $e.parent().addClass('active');
      $('#page')[0].value = pageValue;

      $form[0].submit();
    }
  }
});
