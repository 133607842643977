String.prototype.truncate = function(maxLength, truncationValue = "...") {
  var str = this;
  if (str.length > maxLength) {
      str = (str.slice(0, maxLength) + truncationValue);
  }

  return str;
};

(function() {
  'use strict';
  window.addEventListener('load', function() {
    // var fields = document.getElementsByClassName('custom-file-input');

    $(".custom-file-input").on("change", function() {
      var files = Array.from(this.files)
      var delimiter = `... (${files.length} files selected)`
      var fileName = files.map(f =>{return f.name}).join(", ").truncate(100, delimiter)
      $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
  }, false);
})();
